<template>
  <div class="form-content">
    <img src="@/assets/img/logoTeleSaude.png" class="logo-login">
    <div>
      <div v-if="!showForgotPasswordComponent">
        <card-login @showForgotPassword="() => showForgotPasswordComponent = true" />
      </div>
      <div v-else>
        <card-reset-password @hideForgotPassword="() => showForgotPasswordComponent = false" />
      </div>
      <v-row justify="center" style="padding-top: 10%">
        <v-avatar tile>
          <a href="https://www.ufrgs.br/telessauders/" target="_blank">
            <v-img
              src="@/assets/img/logoTele.svg"
              alt="Telessaude"
              max-height="24px"
              max-width="24px" />
          </a>
        </v-avatar>
        <v-avatar tile>
          <a href="https://www.instagram.com/telessauders" target="_blank">
            <v-img
              src="@/assets/img/logoInstagram.svg"
              alt="Instagram Telessaude"
              max-height="24px"
              max-width="24px" />
          </a>
        </v-avatar>
        <v-avatar tile>
          <a href="https://www.facebook.com/TelessaudeRS/" target="_blank">
            <v-img
              src="@/assets/img/logoFacebook.svg"
              alt="Facebook Telessaude"
              max-height="24px"
              max-width="24px" />
          </a>
        </v-avatar>
        <v-avatar tile>
          <a href="https://br.linkedin.com/company/telessaude-rs-ufrgs" target="_blank">
            <v-img
              src="@/assets/img/logoLinkedin.svg"
              alt="Linkedin Telessaude"
              max-height="24px"
              max-width="24px" />
          </a>
        </v-avatar>
      </v-row>
      <v-row justify="center">
        <v-btn
          class="small-text text-none"
          text
          value="email"
          color="secondary">
          plataforma@telessauders.ufrgs.br
        </v-btn>
        <v-btn class="small-text" text value="fone" color="secondary">
          08006446543
        </v-btn>
      </v-row>
      <v-row justify="center">
        <v-btn
          class="small-text text-none"
          text
          value="termos"
          color="secondary">
          Termos de Uso
        </v-btn>
        <v-btn
          class="small-text text-none"
          text
          value="politica"
          color="secondary">
          Política de Privacidade
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
import CardLogin from '@/components/FormLogin/CardLogin.vue';
import CardResetPassword from '@/components/FormLogin/CardResetPassword.vue';

export default {
  name: 'FormLoginComponent',
  components: {
    'card-login': CardLogin,
    'card-reset-password': CardResetPassword,
  },
  data: (vm) => ({
    showForgotPasswordComponent: vm.$router.currentRoute.hash === '#reset-password',
  }),
}
</script>

<style scoped>
.form-content {
  width: 441px;
  height: auto;
  background-color: #FFFFFF;
  border-radius: 29px;
  padding: 32px 58px;
}

.logo-login {
  width: 100%;
  display: block;
  margin-bottom: 78px;
}

.forms .v-label {
  color: #19B1AB;
  text-align: left;
  font: normal normal 600 14px/35px Lato;
  letter-spacing: 0.12px;
  opacity: 1;
}

.small-text {
  text-transform: inherit;
  text-align: center;
  letter-spacing: 0px;
  opacity: 0.75;
}
</style>
